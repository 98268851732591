export const translations = {
	'en': {
		'email_address_title': 'Email Address',
		'continue_btn': 'Continue',
		'basic_info_title': 'Basic Information',
		// LANDING PAGE
		'regis': 'Registration',
		'regisDesc': 'Start investing securely and reliably with NH Korindo Sekuritas',
		'status_info': 'Already registered? Check your registration status',
		'status_title': 'Registration Status',

		// CITIZENSHIP PAGE
		'idenhelp': '(as per Identity Card)',
		'fullname': 'Full Name',
		'dob': 'Date of Birth',
		'otp': 'Passcode',
		'referral_code': 'RECOMMENDER USER ID (optional)',
		'referral_code_description': 'Input your recommender USER ID here if any (optional)',
		'email': 'Email',
		'phone_number': 'Phone Number',
		'foreigner_1': "to contact our sales support if you don't have Passcode.",
		'foreigner_2': 'if you already have an Passcode from our sales.',
		'request_success_title': 'Request Passcode Successfully',
		'request_success_desc': 'Please check your email to find the Passcode to complete your application.',
		'already_sent': "You've already sent your application, please check your status regularly.",
		'download_form_nh': 'Download Form Application',
		'download_tnc_nh': 'Download Term and Condition',

		// STEP FORM
		'open_account': 'Open a Securities Account',
		'step_1': 'General Information',
		'step_2': 'Job Information',
		'step_3': 'Card Information',
		'step_4': 'Contact for Correspondence',
		'step_5': 'Bank Account',

		// GENERAL INFORMATION
		'first_name': 'First Name',
		'middle_name': 'Middle Name',
		'last_name': 'Last Name',
		'nationality': 'Nationality',
		'pob': 'Place of Birth',
		'gender': 'Gender',
		'marital_status': 'Marital Status',
		'spouse_name': 'Spouse Name',
		'mother_maiden_name': "Mother's Maiden Name",
		'religion': 'Religion',
		'religion_other': 'Other Religion',
		'educational_background': 'Educational Background',
		'income_per_annum': 'Income Per Annum',
		'source_of_fund': 'Source of Fund',
		'source_of_fund_others': 'Others',
		'investment_objectives': 'Investment Objectives',
		'asset_owner': 'Asset Owner',

		// JOB INFORMATION
		'occupation': 'Occupation',
		'job_position': 'Job Position',
		'field_of_business': 'Field of Business',
		'company_name': 'Company Name',
		'monthly_income_range': 'Monthly Income Range',
		'institution_name': 'Institution Name',
		'work_period': 'Work Period',
		'work_period_year': 'Year',
		'work_period_month': 'Month',

		'company_address': 'Company Address',
		'institution_address': 'Institution Address',

		'monthly_income_range_1': '< 2 MILLION',
		'monthly_income_range_2': '> 2 MILLION - 5 MILLION',
		'monthly_income_range_3': '> 5 MILLION - 10 MILLION',
		'monthly_income_range_4': '> 10 MILLION - 20 MILLION',
		'monthly_income_range_5': '> 20 MILLION - 30 MILLION',
		'monthly_income_range_6': '> 30 MILLION - 40 MILLION',
		'monthly_income_range_7': '> 40 MILLION - 50 MILLION',
		'monthly_income_range_8': '> 50 MILLION - 80 MILLION',
		'monthly_income_range_9': '> 80 MILLION',

		'pos_marital_status_1': 'Single',
		'pos_marital_status_2': 'Married',
		'pos_marital_status_3': 'Divorced',
		'pos_marital_status_4': 'Widow/Widower',

		'family_relationship': 'Family Relationship',

		'pos_family_relationship_1': 'HEAD OF FAMILYS',
		'pos_family_relationship_2': 'HUSBAND',
		'pos_family_relationship_3': 'WIFE',
		'pos_family_relationship_4': 'CHILD',
		'pos_family_relationship_5': 'SON IN LAW',
		'pos_family_relationship_6': 'GRANDCHILD',
		'pos_family_relationship_7': 'PARENTS',
		'pos_family_relationship_8': 'PARENTS IN LAW',
		'pos_family_relationship_9': 'OTHER FAMILY',
		'pos_family_relationship_10': 'FAMILY ASSISTANT',
		'pos_family_relationship_11': 'OTHERS',

		'pos_source_of_income_1' : 'Salary',
		'pos_source_of_income_2' : 'Savings',
		'pos_source_of_income_3' : 'Entepreneurship',
		'pos_source_of_income_4' : 'Others',

		'pos_ktp_file': 'BO KTP Image',
		'pos_npwp_file': 'BO NPWP Image',

		// CARD INFORMATION
		'ktp_number': 'KTP Number',
		'ktp_file': 'KTP Image',
		'ktp_expired_date': 'KTP Expired Date',
		'kitas': 'KITAS',
		'kitas_file': 'KITAS Image',
		'kitas_expired_date': 'KITAS Expired Date',
		'npwp_number': 'NPWP Number',
		'npwp_file': 'NPWP Image',
		'npwp_registration_date': 'NPWP Registration Date',
		'passport_number': 'Passport Number',
		'passport_file': 'Passport Image',
		'passport_expired_date': 'Passport Expired Date',
		'photo_selfie_with_ktp': 'Selfie Photo without Holding ID Card/KTP',
		'photo_selfie_with_kitas': 'Selfie Photo with KITAS',
		'example_info_text': 'Examples of taking correct photos',
		'example_sign_info_text': '<p>If you choose a signature in the form of a photo. Ensure that the signature is clearly written on white paper and taken in a landscape position.</p><span>e.g:</span>',

		// BANK ACCOUNT
		'bank_name_exists': 'Existing Bank Account',
		'bank_name': 'Bank Name',
		'bank_book_file': 'Bank Book',
		'account_holder_name': 'Account Holder Name',
		'account_number': 'Account Number',
		'signature': 'Signature',
		'signature_info': 'The electronic signature should be same as Bank Book.',
		'bank_name_other': 'OTHER BANK',

		// SUMMARY
		'summary_title': 'Summary',
		'summary_desc': 'Double check your input data before submitting',

		// SUBMITEDD
		'submit_success_title': 'Submit Successfully',
		'submit_success_desc_1': 'We have to send you the confirmation to your email.',
		'submit_success_desc_2': 'NH Korindo Sekuritas will further process your application. Please check the email for the latest information regarding registration. <br> Thank you for choosing NH Korindo Sekuritas.',

		'check_status_label_1': 'to check your registration status.',
		'check_status_label_2': 'Or visit ',
		'check_status_label_3': 'then select ',
		'check_status_label_4': 'Check Registration Status',

		'start_investing': 'Start Investing Now',
		'app_download_hint': 'Click the logo above to download',
		'download_naik_for_windows': 'Download NAIK for Windows',

		// GLOBAL
		'identity_type': 'ID Type',
		'identity_number': 'Identity Card No',
		'identity_expired': 'Expired Date',
		'occupation_code': 'Occupation Code',
		'occupation_name': 'Occupation Name',
		'occupation_info': 'Example: traders, contractors, etc.',
		'info_fieldset': 'Field marked with (',
		'info_fieldset_2': ') is mandatory field.',
		'label_parent': 'Parent Data',
		'label_spouse': 'Spouse Name Data',
		'fax_number': 'Fax Number',
		'label_wni': 'Indonesian Citizen',
		'label_wna': 'Foreigner',
		'link_back': '< Back',
		'btn_wni': 'Citizen',
		'btn_wna': 'Foreigner',
		'btn_prev': 'Prev',
		'btn_next': 'Next',
		'btn_submit': 'Submit',
		'btn_finish': 'Finish',
		'btn_clear': 'Clear',
		'btn_cancel': 'Cancel',
		'btn_change': 'Change',
		'btn_takephoto': 'Take Photo',
		'btn_choosefile': 'Choose File',
		'placeholder_select': 'Select...',
		'state_here': 'here',
		'loading': 'Loading...',
		'list_photo': 'List of Photo',
		'click_here': 'Click here',
		'legal_privacy': 'Legal & Privacy',
		'lifetime': 'Lifetime',
		'regis_title': 'Open a Securities Account',
		'label_information': 'Information',
		'label_attention': 'Attention',
		// 'information_bca': 'For customer using BCA, all the process of opening account is online.',
		// 'information_bca': '<p>If your existing bank account is BCA or CIMB NIAGA, RDN account opening will be processed automatically (online).</p><p>If your existing bank account is not BCA or CIMB NIAGA, opening of RDN account will be processed manually. You will received an email for more information.</p>',
		// 'information_nonbca': 'RDN non-BCA or CIMB NIAGA will be processed offline. Please print out RDN application form and send it to our office address.</br><br>To: Customer Service</br>Address: Treasury Tower 51th Floor, District 8, SCBD Lot 28,<br> Jl. Jend. Sudirman No.Kav 52-53, RT.5/RW.3, <br>Senayan, Kebayoran Baru, South Jakarta City, Jakarta 12190',
		// 'information_rdn': '<p>Account opening for RDN (Rekening Dana Nasabah) will be processed automatically (online).</p><p>If you select one of the following banks: BCA, CIMB NIAGA, PERMATA BANK, or BRI in the Existing Bank Account option, an RDN account will be automatically created in the bank you selected.</p><p>If you choose a bank other than those mentioned above, you can select one of the available options in the RDN Bank menu.</p>',
		'information_rdn': '<p>Account opening for RDN (Rekening Dana Nasabah) will be processed automatically (online).</p>',
		'text_or': 'OR',

		'address': 'Address',
		'address_info': '(as per Identity Card) example: Jl.Harapan No.12A / Apartemen Contoh Blok A Lantai 2 No.66',
		'country': 'Country',
		'province': 'Province',
		'city': 'City',
		'district': 'District',
		'subdistrict': 'Subdistrict',
		'info_rt_rw': 'e.g: 005',
		'postal_code': 'Post Code',
		'home_phone': 'Home Phone',
		'home_phone_info': '( Area Code - Phone Number )',
		'mobile_number': 'Mobile Phone',
		'hrel_title': 'HREL (Emergency Contacts)',
		'hrel_name': 'HREL Name',
		'hrel_name_help': 'Emergency Contact Name',
		'hrel_address': 'HREL Address',
		'hrel_address_help': '(Emergency Contact Address) Example: Jl.Harapan No.12A / Apartemen Contoh Blok A Lantai 2 No.66',
		'hrel_telp': 'HREL Telephone',
		'hrel_telp_info': 'Emergency Contact Phone',
		'hrell_type': 'HRELL Relations',
		'hrell_type_info': 'Relations with Emergency Contacts (Example: Siblings, Spouse, Parents)',

		'summary_agree': 'I have read and agree to the above statement',

		// ERROR
		// 'error_valueMissing': 'Please fill out this field.',
		'error_valueMissing': 'Field is required.',
		'error_patternMismatch': 'Please match the requested format.',
		// 'error_tooShort': 'Please lengthen this text',
		'error_tooShort': 'Minimum $param$ characters. ($param2$)',
		'error_date_rangeOverflow': 'Date is not valid.',
		'error_date_rangeUnderflow': 'Date is not valid.',
		'error_not_available': 'Not yet Available',

		// BCA AGREE
		'bca_agree1_a': 'I hereby give approval to ',
		'bca_agree1_b': ' to request confirmation of my account data above to PT Bank Central Asia Tbk',
		'bca_agree2': 'I hereby declare that I have read, understood and agree to the contents of the Statement RDN BCA ',
		'bca_agree3': 'I hereby declare that I have read, understood, and agreed to the contents of the provisions related to the Customer Fund Account at BCA as stated',

		'cimb_agree1_a': 'I hereby give approval to ',
		'cimb_agree1_b': ' to request confirmation of my account data above to PT Bank CIMB Niaga Tbk',
		'cimb_agree2': 'I hereby declare that I have read, understood, and agreed to the contents of the provisions related to Ketentuan dan Persyaratan Khusus Pembukaan Rekening Investor',
		'syariah_tnc_agree': 'I have understood and agreed to the Terms and Conditions of Opening a Sharia Account.',

		// DATA MASTER
		'sex_male': 'Male',
		'sex_female': 'Female',
		'pos_gender_male': 'Male',
		'pos_gender_female': 'Female',

		'occupation_1': 'Student',
		'occupation_2': 'Housewife',
		'occupation_3': 'General Employees',
		'occupation_4': 'Government Employees',
		'occupation_5': 'TNI/Polri',
		'occupation_6': 'State / Regional Officials',
		'occupation_7': 'Retired',
		'occupation_8': 'Manufacturing Entrepreneur',
		'occupation_9': 'Traders',
		'occupation_10': 'Service Entrepreneurs',
		'occupation_17': 'Other Professionals',
		'occupation_18': 'Others',

		'pos_occupation_code_1': 'Entrepreneur',
		'pos_occupation_code_2': 'Employee',
		'pos_occupation_code_3': 'Civil Servants',
		'pos_occupation_code_4': 'Others',

		'job_position_1': 'Student',
		'job_position_2': 'Echelon Civil Servant 1',
		'job_position_3': 'Echelon Civil Servant 2',
		'job_position_4': 'Judge',
		'job_position_5': 'Prosecutor',
		'job_position_6': 'Registrar',
		'job_position_7': 'Civil Servant Investigators',
		'job_position_11': 'Ka. Tax office',
		'job_position_12': 'Ka. Customs Office',
		'job_position_13': 'Ka. Treasury Fund',
		'job_position_14': 'Customs Supervisor',
		'job_position_15': 'Investigator',
		'job_position_16': 'High Officer',
		'job_position_17': 'President',
		'job_position_18': 'Vice President',
		'job_position_19': 'MPR Members',
		'job_position_20': 'Members of the House of Representatives',
		'job_position_21': 'DPD Members',
		'job_position_23': 'Chief MA',
		'job_position_24': 'Chairman of the BPK',
		'job_position_25': 'Judicial Commission Member',
		'job_position_26': 'BI Board of Governors',
		'job_position_27': "OJK's Board of Commissioners",
		'job_position_28': 'Chairman of the Commission',
		'job_position_29': 'Governor',
		'job_position_30': 'Deputy Governor',
		'job_position_31': 'Member of DPRD I',
		'job_position_32': 'Regent / Mayor',
		'job_position_33': 'Deputy Regent / Mayor',
		'job_position_34': 'DPRD II Members',
		'job_position_35': 'PTN Leaders',
		'job_position_36': 'Director of BUMN / BUMD',
		'job_position_37': 'Retired Echelon 1/2 Civil Servant',
		'job_position_38': 'Retired High Officer',
		'job_position_39': 'Retired Regional Officials',
		'job_position_40': 'Owner',
		'job_position_41': 'Senior Officer',
		'job_position_42': 'Law Consultant',
		'job_position_43': 'Tax Cconsultant',
		'job_position_44': 'Financial Consultant',
		'job_position_45': 'Political Party Administrators',
		'job_position_46': 'Ormas / LSM Administrators',
		'job_position_47': 'Management of Non-Profit Organizations',
		'job_position_48': 'General Practitioners',
		'job_position_49': 'Medical Specialist',
		'job_position_50': 'Lawyer',
		'job_position_51': 'Accountant',
		'job_position_52': 'Journalist',
		'job_position_53': 'Artist',
		'job_position_54': 'Notary Public',

		'field_of_business_1': 'Education',
		'field_of_business_2': 'Government',
		'field_of_business_3': 'Forest Product Processing',
		'field_of_business_4': 'Art / Antiques',
		'field_of_business_5': 'Gemstones / Precious Metals',
		'field_of_business_6': 'Eczema Oil',
		'field_of_business_7': 'Mineral Eczema',
		'field_of_business_8': 'Gas station',
		'field_of_business_9': 'Mini Market',
		'field_of_business_10': 'Luxury Goods / Vehicles',
		'field_of_business_11': 'Firearms / Explosives / Alutsista',
		'field_of_business_13': 'Remittance',
		'field_of_business_14': 'Precious Metal Investment',
		'field_of_business_15': 'Law Consultant',
		'field_of_business_16': 'Tax Consultant',
		'field_of_business_17': 'Financial Consultant',
		'field_of_business_18': 'Property',
		'field_of_business_20': 'Tour and Travel',
		'field_of_business_22': 'Parking Manager',
		'field_of_business_23': 'Casino / Executive Club',
		'field_of_business_24': 'International Organization',
		'field_of_business_25': 'Law',
		'field_of_business_26': 'Tax',
		'field_of_business_27': 'Financial',
		'field_of_business_28': 'Political',
		'field_of_business_30': 'Non-Profit Organization',
		'field_of_business_31': 'Health',
		'field_of_business_33': 'Art and Culture',
		'field_of_business_34': 'PPAT Law',
		
		// OTHERS
		'optional_text': 'Optional'
	},
	'id': {
		'email_address_title': 'Alamat Email',
		'continue_btn': 'Lanjutkan',
		'basic_info_title': 'Informasi Dasar',
		// LANDING PAGE
		'regis': 'Pendaftaran',
		'regisDesc': 'Mulailah berinvestasi dengan aman dan andal bersama NH Korindo Sekuritas',
		'status_info': 'Sudah mendaftar? Cek status registrasi anda',
		'status_title': 'Status Registrasi',

		// CITIZENSHIP PAGE
		'idenhelp': '(sesuai di KTP)',
		'fullname': 'Nama Lengkap',
		'dob': 'Tanggal Lahir',
		'otp': 'Kode Sandi',
		'referral_code': 'RECOMMENDER USER ID (optional)',
		'referral_code_description': 'Masukkan USER ID pemberi rekomendasi Anda di sini jika ada (opsional)',
		'email': 'Email',
		'phone_number': 'Nomor Telepon',
		'foreigner_1': "untuk menghubungi sales support kami jika anda tidak memiliki Kode Sandi.",
		'foreigner_2': 'jika anda sudah memiliki Passcode dari sales kami.',
		'request_success_title': 'Permintaan Kode Sandi Berhasil',
		'request_success_desc': 'Silakan periksa email anda untuk menemukan Kode Sandi untuk melengkapi aplikasi anda.',
		'already_sent': "Anda telah selesai melakukan pendaftaran, periksa status pendaftaran anda secara berkala.",
		'download_form_nh': 'Unduh Formulir Pengajuan',
		'download_tnc_nh': 'Unduh Syarat dan Ketentuan',

		// STEP FORM
		'open_account': 'Buka Rekening Efek',
		'step_1': 'Informasi Umum',
		'step_2': 'Informasi Pekerjaan',
		'step_3': 'Informasi Kartu',
		'step_4': 'Kontak untuk Korespondensi',
		'step_5': 'Akun Bank',

		// GENERAL INFORMATION
		'first_name': 'Nama Depan',
		'middle_name': 'Nama Tengah',
		'last_name': 'Nama Belakang',
		'nationality': 'Kebangsaan',
		'pob': 'Tempat Lahir',
		'gender': 'Jenis Kelamin',
		'marital_status': 'Status Pernikahan',
		'spouse_name': 'Nama Pasangan',
		'mother_maiden_name': "Nama Gadis Ibu Kandung",
		'religion': 'Agama',
		'religion_other': 'Agama Lain',
		'educational_background': 'Latar belakang pendidikan',
		'income_per_annum': 'Pendapatan Per Tahun',
		'source_of_fund': 'Sumber Dana',
		'source_of_fund_others': 'Lainnya',
		'investment_objectives': 'Tujuan Investasi',
		'asset_owner': 'Pemilik Aset',

		// JOB INFORMATION
		'occupation': 'Pekerjaan',
		'job_position': 'Posisi Pekerjaan',
		'field_of_business': 'Bidang Bisnis',
		'company_name': 'Nama Perusahaan',
		'monthly_income_range': 'Pendapatan Perbulan',
		'institution_name': 'Nama Institusi',
		'work_period': 'Lama Bekerja',
		'work_period_year': 'Tahun',
		'work_period_month': 'Bulan',

		'company_address': 'Alamat Perusahaan',
		'institution_address': 'Alamat Institusi',

		'monthly_income_range_1': '< 2 JUTA',
		'monthly_income_range_2': '> 2 JUTA - 5 JUTA',
		'monthly_income_range_3': '> 5 JUTA - 10 JUTA',
		'monthly_income_range_4': '> 10 JUTA - 20 JUTA',
		'monthly_income_range_5': '> 20 JUTA - 30 JUTA',
		'monthly_income_range_6': '> 30 JUTA - 40 JUTA',
		'monthly_income_range_7': '> 40 JUTA - 50 JUTA',
		'monthly_income_range_8': '> 50 JUTA - 80 JUTA',
		'monthly_income_range_9': '> 80 JUTA',

		'pos_marital_status_1': 'Belum Menikah',
		'pos_marital_status_2': 'Menikah',
		'pos_marital_status_3': 'Cerai',
		'pos_marital_status_4': 'Duda/Janda',

		'family_relationship': 'Hubungan Keluarga',

		'pos_family_relationship_1': 'KEPALA KELUARGA',
		'pos_family_relationship_2': 'SUAMI',
		'pos_family_relationship_3': 'ISTRI',
		'pos_family_relationship_4': 'ANAK',
		'pos_family_relationship_5': 'MENANTU',
		'pos_family_relationship_6': 'CUCU',
		'pos_family_relationship_7': 'ORANG TUA',
		'pos_family_relationship_8': 'MERTUA',
		'pos_family_relationship_9': 'FAMILI LAIN',
		'pos_family_relationship_10': 'PEMBANTU',
		'pos_family_relationship_11': 'LAINNYA',

		'pos_source_of_income_1' : 'Gaji',
		'pos_source_of_income_2' : 'Tabungan',
		'pos_source_of_income_3' : 'Bisnis',
		'pos_source_of_income_4' : 'Lainnya',

		'pos_ktp_file': 'Foto KTP BO',
		'pos_npwp_file': 'Foto NPWP BO',

		// CARD INFORMATION
		'ktp_number': 'Nomor KTP',
		'ktp_file': 'Foto KTP',
		'ktp_expired_date': 'Tanggal Kadaluarsa KTP',
		'kitas': 'KITAS',
		'kitas_file': 'Foto KITAS',
		'kitas_expired_date': 'Tanggal Kadaluarsa KITAS',
		'npwp_number': 'Nomor NPWP',
		'npwp_file': 'Foto NPWP',
		'npwp_registration_date': 'Tanggal Registrasi NPWP',
		'passport_number': 'Nomor Paspor',
		'passport_file': 'Foto Paspor',
		'passport_expired_date': 'Tanggal Kadaluarsa Paspor',
		'photo_selfie_with_ktp': 'Foto Selfie Langsung Tanpa Memegang KTP',
		'photo_selfie_with_kitas': 'Foto Selfie Langsung dengan KITAS',
		'example_info_text': 'Contoh pengambilan foto yang benar',
		'example_sign_info_text': '<p>Jika anda memilih tanda tangan dalam bentuk foto. Pastikan tanda tangan ditulis secara jelas di kertas putih dan diambil dengan posisi landscape.</p><span>Contoh:</span>',

		// BANK ACCOUNT
		'bank_name_exists': 'Akun Bank Yang Sudah Ada',
		'bank_name': 'Nama Bank',
		'bank_book_file': 'Buku Bank',
		'account_holder_name': 'Nama Pemilik Akun',
		'account_number': 'Nomor Akun',
		'signature': 'Tanda tangan',
		'signature_info': 'Tanda tangan elektronik harus sama dengan Buku Bank.',
		'bank_name_other': 'BANK LAIN',

		// SUMMARY
		'summary_title': 'Ringkasan',
		'summary_desc': 'Periksa kembali data masukan Anda sebelum mengirimkan',

		// SUBMITEDD
		'submit_success_title': 'Pendaftaran Berhasil',
		'submit_success_desc_1': 'Kami akan mengirimkan konfirmasi ke email Anda.',
		'submit_success_desc_2': 'NH Korindo Sekuritas akan memproses pendaftaran anda lebih lanjut. Silahkan cek email untuk informasi terbaru mengenai pendaftaran. <br> Terima kasih telah memilih NH Korindo Sekuritas.',

		'check_status_label_1': 'untuk melihat status pendaftaran anda.',
		'check_status_label_2': 'Atau kunjungi ',
		'check_status_label_3': 'kemudian pilih menu ',
		'check_status_label_4': 'Cek Status Registrasi',

		'start_investing': 'Mulai Investasimu Sekarang',
		'app_download_hint': 'Klik pada logo di atas untuk download',
		'download_naik_for_windows': 'Download NAIK untuk Windows',

		// GLOBAL
		'identity_type': 'Tipe ID',
		'identity_number': 'Nomor Kartu Identitas',
		'identity_expired': 'Tanggal Kadaluarsa',
		'occupation_code': 'Kode Pekerjaan',
		'occupation_name': 'Nama Pekerjaan',
		'occupation_info': 'Contoh: pedagang, kontraktor, dll',
		'info_fieldset': 'Kolom yang ditandai (',
		'info_fieldset_2': ') wajib diisi.',
		'fax_number': 'Nomor Fax',
		'label_parent': 'Data Orang Tua',
		'label_spouse': 'Data Nama Pasangan',
		'label_wni': 'Warga Negara Indonesia',
		'label_wna': 'Warga Negara Asing',
		'link_back': '< Kembali',
		'btn_wni': 'WNI',
		'btn_wna': 'WNA',
		'btn_prev': 'Sebelumnya',
		'btn_next': 'Lanjut',
		'btn_submit': 'Kirim',
		'btn_finish': 'Selesai',
		'btn_clear': 'Hapus',
		'btn_cancel': 'Batal',
		'btn_change': 'Ubah',
		'btn_takephoto': 'Ambil Foto',
		'btn_choosefile': 'Pilih File',
		'placeholder_select': 'Pilih...',
		'state_here': 'disini',
		'loading': 'Memuat...',
		'list_photo': 'Daftar Foto',
		'click_here': 'Klik disini',
		'legal_privacy': 'Hukum & Privasi',
		'lifetime': 'Seumur hidup',
		'regis_title': 'Buka Rekening Efek',
		'label_information': 'Informasi',
		'label_attention': 'Perhatian',
		// 'information_bca': 'Untuk nasabah pengguna BCA, semua proses pembukaan rekening dilakukan secara online.',
		// 'information_bca': '<p>Jika rekening eksisting anda adalah BCA atau CIMB NIAGA, tahapan pembukaan akun RDN (Rekening Dana Nasabah) akan diproses otomatis secara online.</p><p>Jika rekening eksisting anda bukan BCA atau CIMB NIAGA, tahapan pembukaan akun RDN akan diproses secara manual. Informasi akan dikirimkan lebih lanjut melalui email.</p>',
		// 'information_nonbca': 'RDN selain BCA atau CIMB NIAGA akan diproses secara offline. Silahkan cetak dokumen formulir pengajuan RDN dan kirimkan ke alamat kantor kami.<br><br>Kepada: Customer Service<br>Alamat: Treasury Tower 51th Floor, District 8, SCBD Lot 28,<br> Jl. Jend. Sudirman No.Kav 52-53, RT.5/RW.3, <br>Senayan, Kebayoran Baru, South Jakarta City, Jakarta 12190',
		// 'information_rdn': '<p>Pembuatan akun RDN akan diproses secara otomatis (online).</p><p>Apabila anda memilih salah satu dari bank berikut: BCA,CIMB NIAGA,PERMATA BANK atau BRI pada opsi <strong>Akun Bank yang Sudah Ada</strong>, maka anda akan dibuatkan rekening RDN di bank yang anda pilih secara otomatis.</p><p>Apabila anda memilih bank selain yang disebutkan di atas, maka anda dapat memilih salah satu yang tersedia pada opsi RDN bank.</p>',
		'information_rdn': '<p>Pembuatan akun RDN akan diproses secara otomatis (online).</p>',
		'text_or': 'ATAU',

		'address': 'Alamat',
		'address_info': '(Sesuai di KTP) Contoh: Jl.Harapan No.12A / Apartemen Contoh Blok A Lantai 2 No.66',
		'country': 'Negara',
		'province': 'Provinsi',
		'city': 'Kota',
		'district': 'Kecamatan',
		'subdistrict': 'Kelurahan',
		'info_rt_rw': 'Contoh: 005',
		'postal_code': 'Kode Pos',
		'home_phone': 'Telepon',
		'home_phone_info': '( Kode Area - Nomor Telepon )',
		'mobile_number': 'Nomor Telepon Genggam',
		'hrel_title': 'HREL (Kontak Darurat)',
		'hrel_name': 'Nama HREL',
		'hrel_name_help': 'Nama Kontak Darurat',
		'hrel_address': 'Alamat HREL',
		'hrel_address_help': 'Alamat Kontak Darurat (Contoh: Jl.Harapan No.12A / Apartemen Blok A Lantai 2 No.66)',
		'hrel_telp': 'Telepon HREL',
		'hrel_telp_info': 'Telepon Kontak Darurat',
		'hrell_type': 'Hubungan HRELL',
		'hrell_type_info': 'Relasi dengan Kontak Darurat (Contoh: Saudara Kandung, Pasangan, Orang Tua, dll.)',

		'summary_agree': 'Saya telah membaca dan menyetujui Pernyataan diatas',

		// ERROR
		// 'error_valueMissing': 'Harap isi kolom ini.',
		// 'error_valueMissing': 'Kolom ini tidak boleh kosong.',
		'error_valueMissing': 'Wajib diisi.',
		'error_patternMismatch': 'Harap cocokkan format yang diminta.',
		// 'error_tooShort': 'Harap perpanjang teks ini',
		'error_tooShort': 'Minimal $param$ karakter. ($param2$)',
		'error_date_rangeOverflow': 'Tanggal tidak valid.',
		'error_date_rangeUnderflow': 'Tanggal tidak valid.',
		'error_not_available': 'Belum Tersedia',

		// BCA AGREE
		'bca_agree1_a': 'Saya dengan ini memberikan persetujuan kepada ',
		'bca_agree1_b': ' untuk meminta konfirmasi atas data rekening saya tersebut di atas kepada PT Bank Central Asia Tbk',
		'bca_agree2': 'Saya dengan ini menyatakan telah membaca, memahami, dan menyetujui isi Pernyataan RDN BCA ',
		'bca_agree3': 'Saya dengan ini menyatakan telah membaca, memahami, dan menyetujui isi dari ketentuan terkait dengan Rekening Dana Nasabah di BCA ',

		'cimb_agree1_a': 'Saya dengan ini memberikan persetujuan kepada ',
		'cimb_agree1_b': ' untuk meminta konfirmasi atas data rekening saya tersebut di atas kepada PT Bank CIMB Niaga Tbk',
		'cimb_agree2': 'Saya dengan ini menyatakan telah membaca, memahami, dan menyetujui isi Ketentuan dan Persyaratan Khusus Pembukaan Rekening Investor ',
		'syariah_tnc_agree': 'Saya telah memahami dan menyetujui Syarat dan Ketentuan Pembukaan Rekening Syariah',

		// DATA MASTER
		'sex_male': 'Pria',
		'sex_female': 'Wanita',
		'pos_gender_male': 'Pria',
		'pos_gender_female': 'Wanita',

		'marital_status_1': 'Lajang',
		'marital_status_2': 'Menikah',
		'marital_status_3': 'Duda',
		'marital_status_4': 'Janda',

		'asset_owner_1': 'Diri Sendiri',
		'asset_owner_2': 'Mewakili Pihak Lain',

		'pos_occupation_code_1': 'Pengusaha',
		'pos_occupation_code_2': 'Karyawan',
		'pos_occupation_code_3': 'Pegawai Negri Sipil',
		'pos_occupation_code_4': 'Lainnya',

		'educational_background_1': 'Lainnya',
		'educational_background_2': 'SD (Sekolah Dasar)',
		'educational_background_3': 'SMP (Sekolah Menengah Pertama)',
		'educational_background_4': 'SMA (Sekolah Menengah Atas), termasuk lulusan akademik D1 dan D2',
		'educational_background_5': 'D3 (Akademi)',
		'educational_background_6': 'S1 (Sarjana)',
		'educational_background_7': 'S2 (Magister)',
		'educational_background_8': 'S3 (Doktoral)',

		'income_per_annum_1': 'Di bawah 10 juta Rupiah',
		'income_per_annum_2': 'Di atas 10 juta Rupiah dan di bawah 50 juta Rupiah',
		'income_per_annum_3': 'Di atas 50 juta Rupiah dan di bawah 100 juta Rupiah',
		'income_per_annum_4': 'Di atas 100 juta Rupiah dan di bawah 500 juta Rupiah',
		'income_per_annum_5': 'Di atas 500 juta Rupiah dan di bawah 1 miliar Rupiah',
		'income_per_annum_6': 'Di atas 1 miliar Rupiah',

		'source_of_fund_1': 'Lainnya',
		'source_of_fund_2': 'Gaji',
		'source_of_fund_3': 'Keuntungan Bisnis',
		'source_of_fund_4': 'Bunga',
		'source_of_fund_5': 'Warisan',
		'source_of_fund_6': 'Dana dari orang tua atau anak',
		'source_of_fund_7': 'Dana dari pasangan',
		'source_of_fund_8': 'Dana pensiun',
		'source_of_fund_9': 'Undian',

		'investment_objectives_1': 'Lainnya',
		'investment_objectives_2': 'Apresiasi Harga',
		'investment_objectives_3': 'Investasi Jangka Panjang',
		'investment_objectives_4': 'Spekulasi',
		'investment_objectives_5': 'Pendapatan',

		'company_province_1000': 'ASING',
		'company_city_1000': 'ASING',

		'province_1000': 'ASING',
		'city_1000': 'ASING',

		// OTHERS
		'optional_text': 'Opsional'
	}
}