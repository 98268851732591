import React, {Fragment, useContext, useState} from 'react';
import { Formik } from 'formik';
import { RootContext } from '../Home';
import style from './CitizenshipPage.module.scss';
import validate from './validate';
import { Form, Col } from 'react-bootstrap';
import CustomInput from '../components/mini/CustomInput';
import AnchorText from '../components/mini/AnchorTextCustom';
import Alert from '../components/mini/AlertCustom';
import Button from '../components/mini/ButtonCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

const months = [
	    "January",
	    "February",
	    "March",
	    "April",
	    "May",
	    "June",
	    "July",
	    "August",
	    "September",
	    "October",
	    "November",
	    "December"
	]

function dateFormat(paramDate) {
	let date = new Date(paramDate);
	return (date.getDate() + ' ' + months[(date).getMonth()] + ' ' + date.getFullYear());
}

const RegistrationStatus = (props) => {
	const store = useContext(RootContext);
	const [citizenship] = useState('wni');
	return (
		<div className={cx(style['citizenship-content'], 'registration-status')}>
			<Formik
				validate={(values) => validate(values, '#citizenship', store.state.lang)}	
				// validationSchema={schema}
				onSubmit={(values, {setSubmitting,resetForm}) => {
			      	store.dispatch({type:'handleSubmit',field:'citizenship', values: values});
			      	// if (!store.state.redirectRegistration) {
			      	// 	setSubmitting(false);
			      	// }
				}}
				initialValues={{
					identitynumber: store.state.citizenship.identitynumber,
					fullname: store.state.citizenship.fullname,
					dob: store.state.citizenship.dob,
					foreigner: !props.citizenship ? false : true,
					otp: store.state.citizenship.otp,
					email: store.state.citizenship.email,
					phonenumber: store.state.citizenship.phonenumber,
				}}
				>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					isSubmitting,
					touched,
					isValid,
					errors,
					setFieldValue,
					setFieldTouched,
				}) => (
					<Fragment>
						<label className="heading">{store.dispatch({type:'translate', 'text': 'status_title'})}</label>
						{
							store.state.errors.global_message !== '' && store.state.errors.global_type === 'danger' ? 
							<Alert 
								type={store.state.errors.global_type}
								message={
									store.dispatch({type:'translate', 'text': store.state.errors.global_message}) ? 
										store.dispatch({type:'translate', 'text': store.state.errors.global_message}) 
										: 
										typeof store.state.errors.global_message === 'object' ? 
											store.state.errors.global_message[store.state.lang]
											:
											store.state.errors.global_message
								}
							/> : ''
						}
						{
							(store.state.submitted.submitted === 1) ? 
							<Fragment>
								<div className="wrap-identity">
									<div className="column">
										<label>No. KTP</label>
										<span>{store.state.submitted.identitynumber}</span>
									</div>
									<div className="column">
										<label>{store.dispatch({type:'translate', 'text': 'fullname'})}</label>
										<span>{store.state.submitted.fullname}</span>
									</div>
									<div className="column">
										<label>{store.dispatch({type:'translate', 'text': 'dob'})}</label>
										<span>{dateFormat(store.state.submitted.dob)}</span>
									</div>
								</div>

								<div className="wrap-summary">
									<div className="step done">
										<div className="title">
				        					<FontAwesomeIcon icon={faCheck} className="icon" />
											Registration
										</div>
										<div className="link-download">
											<div className={(store.state.submitted.application_form_pdf_path !== null) ? "form-app-doclink" : "form-app-doclink process"}>
				        						<FontAwesomeIcon icon={faDownload} className="icon" />
				        						{
													(store.state.submitted.application_form_pdf_path !== null) ? 
													<a rel="noopener noreferrer" href={store.state.submitted.form_application_doclink} target="_blank">{store.dispatch({type:'translate', 'text': 'download_form_nh'})}</a>
													:<Fragment><span className="not-yet">{store.dispatch({type:'translate', 'text': 'download_form_nh'})}</span> <span className="not-available">({store.dispatch({type:'translate', 'text': 'error_not_available'})})</span></Fragment>
												}
											</div>
											<div className={(store.state.submitted.term_service_pdf_path !== null) ? "term-service-doclink" : "term-service-doclink process"}>
				        						<FontAwesomeIcon icon={faDownload} className="icon" />
												{
													(store.state.submitted.term_service_pdf_path !== null) ? 
													<a rel="noopener noreferrer" href={store.state.submitted.term_service_doclink} target="_blank">{store.dispatch({type:'translate', 'text': 'download_tnc_nh'})}</a>
													:<Fragment><span className="not-yet">{store.dispatch({type:'translate', 'text': 'download_tnc_nh'})}</span> <span className="not-available">({store.dispatch({type:'translate', 'text': 'error_not_available'})})</span></Fragment>
												}
											</div>
										</div>
										<div className="info">
										{
											store.dispatch({type:'translate', 'text': store.state.errors.global_message}) ? 
												store.dispatch({type:'translate', 'text': store.state.errors.global_message}) 
												: 
												typeof store.state.errors.global_message === 'object' ? 
													store.state.errors.global_message[store.state.lang]
													:
													store.state.errors.global_message
										}
										</div>
									</div>
									<div className={(store.state.submitted.ksei_status === 'done' && store.state.submitted.ksei_sid) ? "step done" : "step"}>
										<div className="title">
				        					<FontAwesomeIcon icon={faCheck} className="icon" />
											KSEI Process
										</div>
										<div className="link-download">
											{
												store.state.submitted.submitted_syariah &&
												(
													<div className={(store.state.submitted.ksei_sre_syariah) ? "sre" : "sre process"}>
														<FontAwesomeIcon icon={faCheck} className="icon" />
														<span>SRE Syariah (Sub Rekening Efek Syariah)</span>
													</div>
												)
											}
											<div className={(store.state.submitted.ksei_sre) ? "sre" : "sre process"}>
				        						<FontAwesomeIcon icon={faCheck} className="icon" />
				        						<span>SRE (Sub Rekening Efek)</span>
											</div>
											<div className={(store.state.submitted.ksei_sid) ? "sid" : "sid process"}>
				        						<FontAwesomeIcon icon={faCheck} className="icon" />
				        						<span>SID (Single Investor Identification)</span>
											</div>
										</div>
									</div>
									<div className= {store.state.submitted.rdn_number ? "step done" : "step"}>
										<div className="title">
				        					<FontAwesomeIcon icon={faCheck} className="icon" />
											RDN
										</div>
									</div>
								</div>
                            </Fragment>
                            :
                            <Fragment>
								<div className="wrap-btn">
									{/* <Button styleCustom={citizenship === 'wni' ? 'blue-btn' : 'reverse-blue-btn'} value={store.dispatch({type:'translate', 'text': 'btn_wni'})} onButtonClick={() => { setCitizenship('wni'); setFieldValue('foreigner', false); }} /> */}
									{/* <Button styleCustom={citizenship === 'wna' ? 'blue-btn' : 'reverse-blue-btn'} value={store.dispatch({type:'translate', 'text': 'btn_wna'})} onButtonClick={() => { setCitizenship('wna'); setFieldValue('foreigner', true); }} /> */}
								</div>
								<Form id="citizenship" className="form" validated={errors ? false : true } noValidate onSubmit={handleSubmit}>
									<Form.Row>
										<CustomInput 
											col="12"
											labelName={citizenship === 'wni' ? "No. KTP" : 'KITAS'}
											required
											name="identitynumber"
											type="text"
										    minLength={citizenship === 'wni' ? "16" : '10'}
				      						maxLength="16"
				      						pattern={citizenship === 'wni' ? "^[0-9]*$" : "^[A-Z a-z 0-9*$]+"}
				      						info={citizenship === 'wni' ? store.dispatch({type:'translate', 'text': 'idenhelp'}) : false}
				      						value={values.identitynumber}
				      						handleChange={handleChange}
										    isValid={touched.identitynumber && !errors.identitynumber}
										    isInvalid={!!errors.identitynumber}
										    error={(typeof errors.identitynumber === 'object') ? store.dispatch({type:'translate', 'text': errors.identitynumber.type}).toString().replace('$param$', errors.identitynumber.param).replace('$param2$', errors.identitynumber.param2) : store.dispatch({type:'translate', 'text': errors.identitynumber})}
										/>
									</Form.Row>
									<Form.Row>
										<CustomInput 
											col="6"
											labelName={store.dispatch({type:'translate', 'text': 'fullname'})}
											required
											name="fullname"
											type="text"
				      						maxLength="100"
				      						pattern="^[A-Z a-z]+"
				      						value={values.fullname}
				      						handleChange={handleChange}
										    isValid={touched.fullname && !errors.fullname}
										    isInvalid={!!errors.fullname}
										    error={(typeof errors.fullname === 'object') ? store.dispatch({type:'translate', 'text': errors.fullname.type}).toString().replace('$param$', errors.fullname.param).replace('$param2$',errors.fullname.param2) : store.dispatch({type:'translate', 'text': errors.fullname})}
										/>

										<CustomInput 
											col="6"
											labelName={store.dispatch({type:'translate', 'text': 'dob'})}
											required
											name="dob"
											type="date"
				      						dateType="birthdate"
				      						value={values.dob}
				      						handleChange={date => setFieldValue('dob', date)}
										    isValid={touched.dob && !errors.dob}
										    isInvalid={!!errors.dob}
										    error={(typeof errors.dob === 'object') ? store.dispatch({type:'translate', 'text': errors.dob.type}).toString().replace('$param$', errors.dob.param).replace('$param2$',errors.dob.param2) : store.dispatch({type:'translate', 'text': errors.dob})}
										    placeholder="DD/MM/YYYY"
										/>
									</Form.Row>
									{
										citizenship === 'wna' ? 
										<Form.Row>
											<input type="hidden" name="foreigner" value={citizenship === 'wni' ? false : true} />
											<CustomInput 
												col="6"
												labelName={store.dispatch({type:'translate', 'text': 'otp'})}
												required
												name="otp"
												type="text"
					      						maxLength="8"
					      						minLength="8"
				      							pattern="^[0-9]*$"
					      						value={values.otp}
					      						handleChange={handleChange}
											    isValid={touched.otp && !errors.otp}
											    isInvalid={!!errors.otp}
											    error={(typeof errors.otp === 'object') ? store.dispatch({type:'translate', 'text': errors.otp.type}).toString().replace('$param$', errors.otp.param).replace('$param2$',errors.otp.param2) : store.dispatch({type:'translate', 'text': errors.otp})}
											/>
										</Form.Row>
										: ''
									}
									<Form.Row>
										<Col lg={12}>
											<AnchorText text={store.dispatch({type:'translate', 'text': 'link_back'})} className="back-anchor" to="/" />
											{
												(store.state.submitted.submitted !== 1) ?
				     							<Button type="submit" value={store.dispatch({type:'translate', 'text': 'btn_submit'})} loading={store.state.process} disabled={store.state.process}></Button>
				     							: ''
											}
										</Col>
				     				</Form.Row>
								</Form>
                            </Fragment>
						}
					</Fragment>
				)}
			</Formik>
		</div>
	)
}

export default RegistrationStatus;