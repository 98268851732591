import React, { Fragment } from 'react';
import style from './RegistrationPage.module.scss';
import StepForm from '../components/StepForm';

import GeneralInformation from '../components/GeneralInformationForm';
import JobInformation from '../components/JobInformationForm';
import CardInformation from '../components/CardInformationForm';
import ContactForCorrespondence from '../components/ContactForCorrespondenceForm';
// import OtherContact from '../components/OtherContactForm';
import BankAccount from '../components/BankAccountForm';
import {RootContext} from '../Home';
import Alert from '../components/mini/AlertCustom';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import './RegistrationTransition.scss';

export default class RegistrationPage extends React.Component {
	render() {
		return (
            <RootContext.Consumer>
            	{
            		store => {
            			return (
							<Fragment>
								<div className={ style['registration-content'] }>
									<label className={ style['title'] }>{store.dispatch({type:'translate', 'text': 'regis_title'})} </label>
									{store.state.syariahStat ? <label style={{color: '#0172ba', display: 'block', paddingBottom: '15px'}}>Syariah</label> : ''}
									<StepForm currentStep={store.state.step} />
									{store.state.errors.global_message !== '' ? 
										<Alert 
											type={store.state.errors.global_type}
											message={store.dispatch({type:'translate', 'text': store.state.errors.global_message}) ? store.dispatch({type:'translate', 'text': store.state.errors.global_message}) : store.state.errors.global_message}
										/> : ''
									}
									{
									<SwitchTransition mode="out-in">
										<CSSTransition
											key={store.state.step}
											timeout={600}
								            addEndListener={(node, done) => {
								              node.addEventListener("transitionend", done, false);
								            }}
											classNames="fade"
										>
										<div className={"wrap-form"}>
											{ store.state.step === 1 ? <GeneralInformation /> : '' }
											{ store.state.step === 2 ? <JobInformation /> : '' }
											{ store.state.step === 3 ? <CardInformation /> : '' }
											{ store.state.step === 4 ? <ContactForCorrespondence /> : '' }
											{ store.state.step === 5 ? <BankAccount /> : '' }
										</div>
										</CSSTransition>
									</SwitchTransition>
									}
									
								</div>
							</Fragment>
        				)
            		}
            	}
            </RootContext.Consumer>
		);
	}
}